var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        "CreateCard",
        {
          attrs: {
            loading: _vm.loading,
            errorMessage: _vm.message,
            title: _vm.$t("pages.admin.AdminVoucherEdit.createTransaction"),
            buttonEnabled: !!_vm.voucher.voucherScopeType
          },
          on: { handleSubmit: _vm.handleSubmit }
        },
        [
          _c("ValidatedInput", {
            attrs: {
              translationKey: "voucherTransaction.amount",
              field: _vm.$v.voucherTransaction.amount,
              value: _vm.$v.voucherTransaction.amount.$model
            },
            on: {
              "update:value": function($event) {
                return _vm.$set(
                  _vm.$v.voucherTransaction.amount,
                  "$model",
                  $event
                )
              }
            }
          }),
          _c("ValidatedInput", {
            attrs: {
              translationKey: "voucherTransaction.note",
              field: _vm.$v.voucherTransaction.note,
              value: _vm.$v.voucherTransaction.note.$model
            },
            on: {
              "update:value": function($event) {
                return _vm.$set(
                  _vm.$v.voucherTransaction.note,
                  "$model",
                  $event
                )
              }
            }
          }),
          _c("ValidatedSelect", {
            attrs: {
              translationKey: "voucherTransaction.voucherTransactionType",
              field: _vm.$v.voucherTransaction.voucherTransactionType,
              value: _vm.$v.voucherTransaction.voucherTransactionType.$model,
              options: _vm.availableVoucherTransactionTypeOptions
            },
            on: {
              "update:value": function($event) {
                return _vm.$set(
                  _vm.$v.voucherTransaction.voucherTransactionType,
                  "$model",
                  $event
                )
              }
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }